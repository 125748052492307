import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.jpg"; // Import the logo image

const Navbar = () => {
  const openCalendly = () => {
    window.location.href =
      "https://calendly.com/octopihealth/30-minutes-meeting-with-vp";
  };
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img
            src={logo}
            alt="loksun"
            style={{ width: "205px", objectFit: "cover" }} // Adjust the size as needed
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul
            className="navbar-nav"
            style={{ alignContent: "space-around", gap: "1rem" }}
          >
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link active"
                style={{
                  color: "#232323",
                  fontSize: "20",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  wordWrap: "break-word",
                }}
              >
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-link active"
                style={{
                  color: "#232323",
                  fontSize: "20",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  wordWrap: "break-word",
                }}
              >
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                // to="/demo"
                className="nav-link active"
                onClick={openCalendly}
                style={{
                  width: "100%",
                  color: "white",
                  // height: "100%",
                  borderRadius: "10px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  wordWrap: "break-word",
                  backgroundColor: "#05AAF7", // Background color
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none", // Remove default link underline
                }}
              >
                Schedule Demo
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/trail"
                className="nav-link active"
                style={{
                  width: "100%",
                  color: "#05AAF7",
                  borderRadius: "10px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  wordWrap: "break-word",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none", // Remove default link underline
                  border: "1px solid #05AAF7",
                }}
              >
                Free Trail
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
