import React from "react";
import SevenCardsLayout from "./sevencards";
import Testmonial from "./testmonial";
import Complain from "./Complain";
import FAq from "./FAq";
import Status from "./Status";
import { Link } from "react-router-dom";
import News from "./News";

const Home = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Poppins",
  };

  const wordStyle = {
    width: "70%",
    margin: "0 auto",
    color: "#232323",
    fontFamily: "Montserrat",
    fontSize: "25",
    fontWeight: 500,
    wordWrap: "break-word",
    marginTop: "5px",
  };

  const cardStyle = {
    background: "#05AAF72E",
    width: "70%",
    margin: "20px auto",
    borderRadius: "10px",
  };

  const cardContentStyle = {
    color: "#686868",
    fontFamily: "Montserrat",
    fontSize: "18",
    fontWeight: 500,
    wordWrap: "break-word",
  };

  return (
    <div style={containerStyle}>
      <img
        src={require("../images/Group 31.png")}
        alt="loksun"
        style={{ width: "50%", height: "50%" }}
      />
      <div style={{ width: "100%" }}>
        <span
          style={{
            color: "#232323",
            fontSize: "35px",
            fontWeight: 500,
            wordWrap: "break-word",
            display: "inline-block",
            marginRight: "10px", // Add some space between the spans on large screens
          }}
        >
          Revolutionizing
        </span>
        <span
          style={{
            color: "#05AAF7",
            fontSize: "35px",
            fontWeight: 700,
            wordWrap: "break-word",
            display: "inline-block",
          }}
        >
          Medical Documentation
        </span>
      </div>
      <div className="word" style={{ ...wordStyle, marginBottom: "20px" }}>
        Transforming healthcare documentation. Innovative tech with computer
        vision, and language processing for precise doctor-patient interaction.
        Goodbye to tedious notes, hello to efficient care.
      </div>
      <Link to="/video" style={{ textDecoration: "none" }}>
        <button
          style={{
            borderRadius: 5,
            border: "2px #05AAF7 solid",
            marginTop: "5px",
            width: "200px", // Set the desired width
            backgroundColor: "white",
            color: "#05AAF7",
            marginBottom: "20px", // Add margin-bottom for the button
          }}
        >
          Watch Video
        </button>
      </Link>

      <div className="card" style={cardStyle}>
        <div className="card-body" style={cardContentStyle}>
          <h2
            style={{
              color: "#05AAF7",
              fontSize: "30",
              fontWeight: 700,
              wordWrap: "break-word",
            }}
          >
            How it Works?
          </h2>
          Octopi uses artificial intelligence to visually observe the patient
          and listen to the conversation between the doctor and patient. Our
          sophisticated algorithm analyzes the visual and audio inputs,
          identifying key phrases, symptoms, and diagnoses. This information is
          then used to generate accurate voice notes and update any EMR system
          in real-time, ensuring seamless integration and accuracy.
        </div>
      </div>
      <div
        style={{
          color: "#05AAF7",
          fontSize: "30px",
          fontFamily: "Poppins",
          fontWeight: 600,
          wordWrap: "break-word",
        }}
      >
        Benefits of Octopi
      </div>
      <SevenCardsLayout />
      <div
        style={{
          color: "#05AAF7",
          fontSize: "30px",
          fontFamily: "Poppins",
          fontWeight: 600,
          wordWrap: "break-word",
          marginTop: "15px",
        }}
      >
        Testimonials
      </div>
      <div
        className="word"
        style={{
          width: "80%",
          margin: "0 auto",
          color: "#686868",
          fontFamily: "Montserrat",
          fontSize: "20",
          fontWeight: 500,
          wordWrap: "break-word",
        }}
      >
        Don't just take our word for it. Here's what some of our satisfied
        clients say about Octopi:
      </div>
      <Testmonial />
      <div
        style={{
          color: "#05AAF7",
          fontSize: "30px",
          fontFamily: "Poppins",
          fontWeight: 600,
          wordWrap: "break-word",
          marginTop: "15px",
        }}
      >
        We’re in News
      </div>
      <News />
      <div
        style={{
          color: "#05AAF7",
          fontSize: "30px",
          fontFamily: "Poppins",
          fontWeight: 600,
          wordWrap: "break-word",
          marginTop: "15px",
        }}
      >
        Compliance's
      </div>
      <div
        className="word"
        style={{
          width: "80%",
          margin: "0 auto",
          color: "#686868",
          fontFamily: "Montserrat",
          fontSize: "20",
          fontWeight: 500,
          wordWrap: "break-word",
          textAlign: "left",
        }}
      >
        Octopi Health prioritizes rigorous regulatory compliance, ensuring the
        highest standards of data security and patient privacy in every aspect
        of our innovative healthcare documentation solution.
      </div>
      <Complain />
      <div
        style={{
          color: "#05AAF7",
          fontSize: "30px",
          fontFamily: "Poppins",
          fontWeight: 600,
          wordWrap: "break-word",
          marginTop: "20px",
          // marginRight: "1080px",
        }}
      >
        FAQ’s
      </div>
      <FAq />
      <Status />
    </div>
  );
};

export default Home;
