import React, { useState } from "react";
import "./Contact.css";
import contact from "../images/Contact us-bro.svg";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    const templateParams = {
      user_name: formData.name,
      user_email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_y3v9h59",
        "template_rqi54gy",
        templateParams,
        "UAFeAVJXC3Se11WpH"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(
            "Thank you for providing your details! Your information has been received successfully."
          );
        },
        (error) => {
          console.log(error.text);
        }
      );

    console.log("Form Data:", formData);
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="contact-container">
      <div className="contact-text">
        <div
          style={{
            color: "#05AAF7",
            fontSize: 30,
            fontFamily: "Poppins",
            fontWeight: "600",
            marginBottom: "10px",
          }}
        >
          Contact Us
        </div>
        <p
          style={{
            color: "#232323",
            fontSize: 22,
            fontFamily: "Montserrat",
            marginBottom: "20px",
          }}
        >
          Feel free to reach out or message us anytime. We're here to assist
          you!
        </p>
        <form onSubmit={handleSendMessage}>
          <div
            style={{
              color: "#232323",
              fontSize: 22,
              fontFamily: "Poppins",
              fontWeight: "400",
              marginBottom: "5px",
            }}
          >
            Your Name
          </div>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={formData.name}
            required
            style={{
              width: "100%",
              height: "50px",
              borderRadius: 5,
              border: "1px #CCCCCC solid",
              marginBottom: "10px",
              fontSize: 18,
            }}
          />
          <div
            style={{
              color: "#232323",
              fontSize: 22,
              fontFamily: "Poppins",
              fontWeight: "400",
              marginBottom: "5px",
            }}
          >
            Your Email
          </div>
          <input
            type="text"
            name="email"
            onChange={handleChange}
            value={formData.email}
            required
            style={{
              width: "100%",
              height: "50px",
              borderRadius: 5,
              border: "1px #CCCCCC solid",
              marginBottom: "10px",
              fontSize: 18,
            }}
          />
          <div
            style={{
              color: "#232323",
              fontSize: 22,
              fontFamily: "Poppins",
              fontWeight: "400",
              marginBottom: "5px",
            }}
          >
            Your Message
          </div>
          <input
            type="text"
            name="message"
            onChange={handleChange}
            required
            value={formData.message}
            style={{
              width: "100%",
              height: "50px",
              borderRadius: 5,
              border: "1px #CCCCCC solid",
              marginBottom: "10px",
              fontSize: 18,
            }}
          />
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              style={{
                background: "#05AAF7",
                borderRadius: 15,
                color: "white",
                border: "none",
                cursor: "pointer",
                fontSize: 18,
                fontFamily: "Poppins",
                fontWeight: "400",
                padding: "10px 20px",
              }}
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
      <div className="contact-image">
        <img
          src={contact}
          alt="Contact Us"
          style={{
            width: "60%",
            height: "auto",
            marginTop: "-45px",
            marginLeft: "20%",
          }}
        />
        <div className="contact-info">
          <div
            style={{
              color: "#686868",
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "400",
              wordWrap: "break-word",
              marginLeft: "10%",
              textAlign: "center",
            }}
          >
            <span>&#9742;</span> +1-2674921698
          </div>
          <div
            style={{
              color: "#686868",
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "400",
              wordWrap: "break-word",
              marginLeft: "10%",
              textAlign: "center",
            }}
          >
            <span>&#9993;</span> hello@octopi.health
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
