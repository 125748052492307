import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const FAQ = () => {
  const [isOpen, setIsOpen] = useState(Array(5).fill(false)); // Array to track the open/closed state of each item

  const toggleAccordion = (index) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
  };

  return (
    <div style={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
      {faqData.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "20px",
            gap: "1rem",
            borderBottom: "1px solid #ccc",
            paddingBottom: "1rem",
            textAlign: "justify",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                marginRight: "10px",
                color: "#05AAF7",
                fontSize: "24px",
              }}
              onClick={() => toggleAccordion(index)}
            >
              {isOpen[index] ? <FaMinus /> : <FaPlus />}
            </div>
            <div
              style={{
                flex: "1",
                color: "black",
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: "500",
                wordWrap: "break-word",
                cursor: "pointer",
              }}
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
            </div>
          </div>
          {isOpen[index] && (
            <div
              style={{
                color: "#686868",
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: "400",
                wordWrap: "break-word",
                paddingLeft: "10px",
                textAlign: "leftAlign",
              }}
            >
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const faqData = [
  {
    question: "Is Octopi compatible with our current EMR system?",
    answer:
      "Yes, our technology can integrate with most EMR systems, ensuring seamless compatibility and minimal disruption to your operations.",
  },
  {
    question: "How does Octopi ensure accuracy in voice notes and EMR updates?",
    answer:
      "Our AI algorithm undergoes continuous training and learning, refining its accuracy over time. Additionally, our system includes built-in checks and balances to verify the accuracy of the information captured.",
  },
  {
    question: "Can Octopi handle multiple languages or accents?",
    answer:
      "Yes, our technology is designed to accommodate diverse linguistic and cultural backgrounds. We recognize that healthcare is a global industry, and our solution reflect.",
  },
  {
    question: "Does Octopi require EMR integration?",
    answer:
      "Not really, Octopi sits on top of your EMR solution and doesn't required any support from EMR providers.",
  },
];

export default FAQ;
