import React, { useState, useEffect } from "react";
import "./test.css"; // Import your CSS file
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import testimonial1Image from "../images/Octopi.health/Digital-Journal-Logosm 1.png";
import testimonial2Image from "../images/Octopi.health/Group 32.png";
import testimonial3Image from "../images/Octopi.health/american-tribune-logo 1.png";
import testimonial4Image from "../images/Octopi.health/rating_logo-300x99 1.png";
import testimonial5Image from "../images/Octopi.health/USA-Verdict-Logo-Dark 1.png";

const News = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalTestimonials);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [currentSlide]);

  const totalTestimonials = 5;

  // Define an array of testimonials with image and link data
  const testimonials = [
    {
      image: testimonial1Image,
      link: "https://www.digitaljournal.com/pr/news/octopi-health-launches-sensor-free-body-motion-tracking-for-physiotherapy-combining-privacy-and-convenience",
    },
    {
      image: testimonial2Image,
      link: "https://www.marketwatch.com/press-release/octopihealth-launches-sensor-free-body-motion-tracking-for-physiotherapy-combining-privacy-and-convenience-2023-02-02?mod=search_headline",
    },
    {
      image: testimonial3Image,
      link: "https://americantribune.co/octopi-health-launches-sensor-free-body-motion-tracking-for-physiotherapy-combining-privacy-and-convenience",
    },
    {
      image: testimonial4Image,
      link: "https://www.usaherald.online/octopi-health-launches-sensor-free-body-motion-tracking-for-physiotherapy-combining-privacy-and-convenience",
    },
    {
      image: testimonial5Image,
      link: "https://usaverdict.com/octopi-health-launches-sensor-free-body-motion-tracking-for-physiotherapy-combining-privacy-and-convenience",
    },
  ];

  return (
    <div className="container">
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        infiniteLoop
        selectedItem={currentSlide}
        // Removed height style for the carousel
      >
        {/* Render each testimonial dynamically */}
        {testimonials.map((testimonial, index) => (
          <div className="card" style={cardStyle} key={index}>
            <div style={{ flexDirection: "column" }}>
              <img
                src={testimonial.image}
                className="responsive"
                alt={`Testimonial ${index + 1}`}
                style={{
                  width: "70%", // Make all images the same width
                  alignItems: "center",
                  // height: "auto", // Allow the image to maintain its aspect ratio
                  maxHeight: "150px", // Adjust the maximum height of each image
                  objectFit: "contain", // Ensure the image fits within the specified height
                  marginBottom: "10px",
                }}
              />

              <p
                style={{
                  color: "#2E2E2E",
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  lineHeight: "25px",
                  wordWrap: "break-word",
                  margin: "10px 0", // Adjust the margin
                  textAlign: "justify",
                  // overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  flex: "1", // Make the paragraph take up remaining space
                }}
              >
                <a
                  href={testimonial.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#FFFFFF",
                    textDecoration: "underline",
                    display: "block",
                  }}
                >
                  {testimonial.link}
                </a>
              </p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const cardStyle = {
  background: "#05AAF7",
  padding: "10px",
  border: "1px solid #ddd",
  boxSizing: "border-box",
  marginBottom: "20px",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
  height: "250px",
};

export default News;
