import React, { useState } from "react";
import emailjs from "emailjs-com";

const Trail = () => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use your own EmailJS template and service IDs
    const templateParams = {
      user_name: `${formData.firstName} ${formData.lastName}`,
      user_email: formData.email,
      contact_number: formData.contactNo,
      message: formData.description,
    };

    emailjs
      .send(
        "service_y3v9h59",
        "template_ipg8f07",
        templateParams,
        "UAFeAVJXC3Se11WpH"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(
            "Thank you for providing your details! Your information has been received successfully."
          );
        },
        (error) => {
          console.log(error.text);
        }
      );

    console.log("Form Data:", formData);
    setFormData(initialFormData); // Reset the form data to clear input fields
  };

  return (
    <div className="container">
      <div
        className="card mt-4"
        style={{ backgroundColor: "#05AAF733", width: "100%" }}
      >
        <div className="card-body">
          <form id="myForm" onSubmit={handleSubmit}>
            <div
              style={{
                color: "#05AAF7",
                fontSize: 30,
                fontFamily: "Poppins",
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Free Trial
            </div>

            {/* First Name and Last Name */}
            <div className="mb-3 row">
              <div className="col">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter your first name"
                  onChange={handleChange}
                  value={formData.firstName}
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter your last name"
                  onChange={handleChange}
                  value={formData.lastName}
                  required
                />
              </div>
            </div>

            {/* Email and Contact No */}
            <div className="mb-3 row">
              <div className="col">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="contactNo" className="form-label">
                  Contact No
                </label>
                <input
                  type="tel"
                  pattern="[0-9]*"
                  className="form-control"
                  id="contactNo"
                  placeholder="Enter your contact number"
                  onChange={handleChange}
                  value={formData.contactNo}
                  required
                />
              </div>
            </div>

            {/* Description */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                rows="3"
                placeholder="Enter a description"
                onChange={handleChange}
                value={formData.description}
                required
              ></textarea>
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                style={{
                  background: "#05AAF7",
                  borderRadius: 15,
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  fontSize: 18,
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  padding: "10px 20px", // Increase the padding to make the button larger
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Trail;
