import React from "react";
import ReactPlayer from "react-player";

const Video = () => {
  // const videoUrl =
  //   "https://drive.google.com/uc?id=12rIDCQjlqpJb42CkdJXlmzzP8m9o207P";

  const videoPath = require("../videos/demo.mp4");
  return (
    <div style={styles.card}>
      <h2 style={styles.heading}> Demo Illustration </h2>
      <div style={styles.videoContainer}>
        <ReactPlayer
          url={videoPath}
          controls
          playing
          width="600px"
          height="400px"
        />
      </div>
    </div>
  );
};

const styles = {
  card: {
    // border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "800px",
    margin: "auto",
    marginTop: "20px",
  },
  heading: {
    textAlign: "center",
    color: "#05AAF7",
    fontSize: 30,
    fontFamily: "Poppins",
    fontWeight: "600",
    wordWrap: "break-word",
  },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
};

export default Video;
