import React from "react";
import { Link } from "react-router-dom";
import "./status.css";

const Status = () => {
  const openCalendly = () => {
    window.location.href =
      "https://calendly.com/octopihealth/30-minutes-meeting-with-vp";
  };

  return (
    <div
      className="status-container"
      style={{
        display: "flex",
        background: "#05AAF7",
        borderRadius: "10px",
        padding: "20px",
        boxSizing: "border-box",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        alignItems: "center",
        marginTop: "10px",
        width: "80%", // Adjusted width to 80%
        margin: "0 auto", // Center the container
      }}
    >
      <div
        style={{
          textAlign: "left", // Align content to the left
          width: "100%", // Adjusted width to 100%
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: "30px",
            fontFamily: "Poppins",
            fontWeight: "500",
            wordWrap: "break-word",
            marginBottom: "10px",
          }}
        >
          Get Started Today
        </div>
        <div
          style={{
            color: "white",
            fontSize: "22px",
            fontFamily: "Montserrat",
            fontWeight: "400",
            wordWrap: "break-word",
            textAlign: "left",
          }}
        >
          Schedule & Experience Free Trial with no strings attached to
          streamline your workflows, increase revenue and enhance patient care !
        </div>
      </div>

      <div
        className="status-container"
        style={{
          display: "flex",
          justifyContent: "flex-start", // Align items to the left
          background: "#05AAF7",
          borderRadius: "10px",
          // padding: "20px",
          boxSizing: "border-box",
          color: "#FFFFFF",
          fontFamily: "Poppins",
          // alignItems: "center",
          marginTop: "10px",
          width: "80%", // Adjusted width to 80%
          margin: "0 auto", // Center the container
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: "70%", // Adjusted width to 70%
            marginTop: "15px",
          }}
        >
          <div className="button" style={{ marginBottom: "10px" }}>
            <Link to="#" style={{ textDecoration: "none", width: "100%" }}>
              <button
                onClick={openCalendly}
                style={{
                  width: "130%",
                  background: "white",
                  borderRadius: 8, // Reduced border-radius
                  color: "#05AAF7",
                  border: "1px white solid",
                  marginBottom: "10px",
                  // width: "200px",
                  // textAlign: "center",
                  // marginLeft: "-50px",
                }}
              >
                Schedule Demo
              </button>
            </Link>

            <Link to="/trail" style={{ textDecoration: "none", width: "100%" }}>
              <button
                style={{
                  width: "130%",
                  borderRadius: 8, // Reduced border-radius
                  border: "1px white solid",
                  backgroundColor: "transparent",
                  color: "white",
                  // width: "200px",
                  // textAlign: "center",
                  // marginLeft: "-50px",
                }}
              >
                Free Trial
              </button>
            </Link>
          </div>

          <div>
            <div
              style={{
                color: "white",
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "500",
                wordWrap: "break-word",
                marginBottom: "10px",
              }}
            >
              Contact Us
            </div>
            <div
              style={{
                color: "white",
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "400",
                wordWrap: "break-word",
                marginBottom: "10px",
                textAlign: "justify",
              }}
            >
              Phone No: 📞 +1-2674921698
            </div>
            <div
              style={{
                color: "white",
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "400",
                // wordWrap: "break-word",
                marginBottom: "10px",
              }}
            >
              Email: ✉️ hello@octopi.health
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
