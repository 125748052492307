import React from "react";
import Hippa from "../images/HIPPA-Compliance.png";
import Soc from "../images/6438bcac21aecc244d5ef4e5_SOC logo.png";
import Gdpr from "../images/648dcc46e26cd07c0ca0a4dd_gdpr.png";
import "./Complain.css"; // Import the CSS file for styling

const Complain = () => {
  return (
    <div className="complain-container">
      <img src={Hippa} alt="Hippa Compliance" className="complain-image" />
      <img src={Gdpr} alt="GDPR" className="complai-image" />
      <img src={Soc} alt="SOC Logo" className="complai-image" />
    </div>
  );
};

export default Complain;
