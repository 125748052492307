import React from "react";
import laptopScreenImage from "../images/laptop-screen 1.png";
import document from "../images/document 1.png";
import administration from "../images/administration 1.png";
import healthy from "../images/healthy 1.png";
import focus from "../images/focus 1.png";
import gear from "../images/integration 1.png";
import deeplearning from "../images/profit-growth 1.png";

const Card = ({ image, heading, content }) => (
  <div style={styles.card}>
    <img src={image} alt={heading} style={styles.image} />
    <h3 style={styles.heading}>{heading}</h3>
    <p style={styles.content}>{content}</p>
  </div>
);

const SevenCardsLayout = () => (
  <div style={styles.container}>
    <div style={styles.sideBySide}>
      <Card
        image={laptopScreenImage}
        heading="Reduced Screen Time"
        content="Minimize the time spent on screens, allowing healthcare professionals to focus on providing quality care instead of tedious documentation. With Octopi, doctors can spend less time typing and more time engaging with patients."
      />
      <Card
        image={document}
        heading="Less Documentation Time"
        content="No more lengthy dictations or manual note-taking, freeing up valuable time for more important tasks. Our technology takes care of documentation, leaving doctors and medical staff more time for patient care and other critical responsibilities."
      />
    </div>
    <div style={styles.sideBySide}>
      <Card
        image={administration}
        heading="Streamlined Administrative Work"
        content="Our system handles routine administrative tasks, ensuring seamless integration with existing EMR systems. This reduces the burden on medical staff, allowing them to focus on higher-priority tasks and improve overall operational efficiency."
      />
      <Card
        image={healthy}
        heading="Improved Patient Experience"
        content="Patients benefit from more personalized attention and faster treatment, thanks to reduced documentation burden on healthcare providers. With Octopi, doctors can dedicate more time to patient consultations and less time to paperwork."
      />
    </div>

    <div style={styles.sideBySide}>
      <Card
        image={focus}
        heading="Increased Accuracy"
        content="Our AI technology ensures that medical records are accurate and comprehensive, reducing errors and inconsistencies. This leads to better patient outcomes and fewer mistakes in medication, treatments, and billing."
      />
      <Card
        image={gear}
        heading="Customizable Integration"
        content="Our flexible API allows for easy integration with various EMR systems, adapting to your facility's unique needs and infrastructure. We work closely with your team to ensure a seamless implementation process."
      />
    </div>
    <div style={styles.centeredCard}>
      <Card
        image={deeplearning} // Replace with the URL to your image
        heading="Increase Revenue"
        content=" Elevate your revenue with streamlined workflows, increased efficiency, and enhanced patient care, placing your practice on a path of financial growth and success."
      />
    </div>
  </div>
);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sideBySide: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
    width: "100%",
    flexWrap: "wrap", // Allow cards to wrap to the next line on smaller screens
  },
  centeredCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%",
  },
  card: {
    textAlign: "center",
    maxWidth: "400px",
    padding: "20px",
    boxShadow: "0px 4px 4px rgba(5, 170, 247, 0.18)",
    borderRadius: "8",
    margin: "0 10px",
  },
  image: {
    maxWidth: "100%", // Set the maximum width to 100% to ensure responsiveness
    maxHeight: "200px", // Set a maximum height if needed to control image size
    height: "auto", // Maintain aspect ratio
    borderRadius: "8",
    marginBottom: "10",
  },
  heading: {
    color: "#2E2E2E",
    fontSize: "25",
    fontFamily: "Poppins",
    fontWeight: 600,
    wordWrap: "break-word",
  },
  content: {
    color: "#686868",
    fontSize: "18",
    fontFamily: "Montserrat",
    fontWeight: 400,
    wordWrap: "break-word",
    textAlign: "justify",
  },
  // Add media queries for smaller screens
  "@media only screen and (max-width: 768px)": {
    sideBySide: {
      flexDirection: "column", // Change the layout to column on smaller screens
    },
  },
};

export default SevenCardsLayout;
