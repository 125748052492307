import React, { useState, useEffect } from "react";
import "./test.css"; // Import your CSS file
import { Carousel } from "react-responsive-carousel";
import quote from "../images/quote 2.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalTestimonials);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentSlide]);

  const totalTestimonials = 3;

  return (
    <div className="container">
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        infiniteLoop
        selectedItem={currentSlide}
        style={{ height: "400px" }} // Set a fixed height for the Carousel
      >
        {/* Testimonial 1 */}
        <div className="card" style={cardStyle}>
          <img
            style={{
              width: "50px",
              height: "45px",
              position: "absolute",
              top: "-9px",
              right: "10px",
            }}
            src={quote}
            alt="quotation mark"
          />
          <p
            style={{
              color: "#2E2E2E",
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "25px",
              wordWrap: "break-word",
              marginTop: "20px",
              marginLeft: "20px",
              textAlign: "justify",
            }}
          >
            "Octopi has been a game-changer for my practice. I no longer spend
            hours dictating notes or struggling with EMR systems. Their
            technology has freed up so much time for me to focus on my
            patients."
          </p>
          <h6
            style={{
              marginLeft: "5%" /* Adjust the left margin for smaller screens */,
              marginRight:
                "5%" /* Adjust the right margin for smaller screens */,
              color: "#686868",
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "25px",
              wordWrap: "break-word",
              textAlign: "center" /* Center text on smaller screens */,
            }}
          >
            - Dr. Sarah Johnson, Family Medicine Physician
          </h6>
          <span
            style={{
              display: "block" /* Make the span a block element */,
              color: "#05AAF7",
              marginLeft:
                "auto" /* Center the span by setting left and right margins to auto */,
              marginRight: "auto",
              fontSize: "24px",
              textAlign: "center" /* Center text on smaller screens */,
            }}
          >
            ★ ★ ★ ★ ★
          </span>
        </div>

        {/* Testimonial 2 */}
        <div className="card" style={cardStyle}>
          <img
            style={{
              width: "50px",
              height: "45px",
              position: "absolute",
              top: "-9px",
              right: "10px",
            }}
            src={quote}
            alt="quotation mark"
          />
          <p
            style={{
              color: "#2E2E2E",
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "25px",
              wordWrap: "break-word",
              marginTop: "20px",
              marginLeft: "20px",
              textAlign: "justify",
            }}
          >
            "The accuracy of the voice notes generated by Octopi is impressive.
            It's like having a personal assistant who never makes mistakes."
          </p>
          <h6
            style={{
              marginLeft: "5%" /* Adjust the left margin for smaller screens */,
              marginRight:
                "5%" /* Adjust the right margin for smaller screens */,
              color: "#686868",
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "25px",
              wordWrap: "break-word",
              textAlign: "center" /* Center text on smaller screens */,
            }}
          >
            - Dr. Mark Smith, Cardiologist
          </h6>
          <span
            style={{
              display: "block" /* Make the span a block element */,
              color: "#05AAF7",
              marginLeft:
                "auto" /* Center the span by setting left and right margins to auto */,
              marginRight: "auto",
              fontSize: "24px",
              textAlign: "center" /* Center text on smaller screens */,
            }}
          >
            ★ ★ ★ ★
          </span>
        </div>

        {/* Testimonial 3 */}
        <div className="card" style={cardStyle}>
          <img
            style={{
              width: "50px",
              height: "45px",
              position: "absolute",
              top: "-9px",
              right: "10px",
            }}
            src={quote}
            alt="quotation mark"
          />
          <p
            style={{
              color: "#2E2E2E",
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "25px",
              wordWrap: "break-word",
              marginTop: "20px",
              marginLeft: "20px",
              textAlign: "justify",
            }}
          >
            "With Octopi, we've seen a significant reduction in documentation
            time and errors. It's allowed us to allocate resources more
            effectively and improve patient care."
          </p>
          <h6
            style={{
              marginLeft: "5%" /* Adjust the left margin for smaller screens */,
              marginRight:
                "5%" /* Adjust the right margin for smaller screens */,
              color: "#686868",
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "25px",
              wordWrap: "break-word",
              textAlign: "center" /* Center text on smaller screens */,
            }}
          >
            - Mary Johnson, Clinic Manager
          </h6>
          <span
            style={{
              display: "block" /* Make the span a block element */,
              color: "#05AAF7",
              marginLeft:
                "auto" /* Center the span by setting left and right margins to auto */,
              marginRight: "auto",
              fontSize: "24px",
              textAlign: "center" /* Center text on smaller screens */,
            }}
          >
            ★ ★ ★ ★ ★
          </span>
        </div>

        {/* Add more testimonials as needed */}
      </Carousel>
    </div>
  );
};

const cardStyle = {
  background: "rgba(5, 170, 247, 0.18)",
  padding: "10px",
  border: "1px solid #ddd",
  boxSizing: "border-box",
  marginBottom: "20px",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
  height: "100%", // Set a fixed height for the cards
};

export default Testimonial;
