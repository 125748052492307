import React from "react";
import "./Contact.css"; // Import your stylesheet
import contact from "../images/Contact us-bro.svg";

const Demo = () => {
  const openCalendly = () => {
    window.location.href =
      "https://calendly.com/octopihealth/30-minutes-meeting-with-vp";
  };

  return (
    <div className="contact-container">
      <div className="contact-text">
        <div
          style={{
            color: "#05AAF7",
            fontSize: "2rem", // Responsive font size
            fontFamily: "Poppins",
            fontWeight: "600",
            marginBottom: "10px",
          }}
        >
          Schedule Demo
        </div>
        <p
          style={{
            color: "#232323",
            fontSize: "1.5rem", // Responsive font size
            fontFamily: "Montserrat",
            marginBottom: "20px",
          }}
        >
          Schedule a Demo with Us – Effortless Booking via Google Calendar!
        </p>
        <button
          className="demo-button"
          onClick={openCalendly}
          style={{
            borderRadius: "15px",
            border: "2px #05AAF7 solid",
            color: "#05AAF7", // text color
            backgroundColor: "white", // background color
            cursor: "pointer", // change cursor on hover
            fontSize: "1rem", // Responsive font size
            fontFamily: "Poppins",
            fontWeight: "600",
            // width: "100%", // Responsive width
          }}
        >
          Schedule Your Demo
        </button>
      </div>
      <div className="contact-image">
        <img
          src={contact}
          alt="Contact Us"
          style={{
            // width: "100%", // Responsive width
            height: "auto",
            marginTop: "-45px",
            marginLeft: "0", // Adjust margin for responsiveness
          }}
        />
      </div>
    </div>
  );
};

export default Demo;
