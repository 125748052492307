import React from "react";
import "./App.css";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Navbar from "./components/inc/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Demo from "./components/pages/Demo";
import Trial from "./components/pages/Trial";
import Video from "./components/pages/Video";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/demo">
            <Demo />
          </Route>
          <Route exact path="/trail">
            <Trial />
          </Route>
          <Route exact path="/video">
            <Video />
          </Route>
          {/* Redirect to home for any unknown route */}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
